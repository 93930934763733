.cont_login{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh; /* Ensures the container takes the full viewport height */

}
#login-form {
    width: 400px;
    max-width: 100%;
    margin: 150px auto;
    background-color: #5c88d3;    ;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: "Poppins", sans-serif;
    
  }
  
  #login-form h1 {
    text-align: center;
    margin: 0;
    padding: 20px 0;
    font-size: 28px;
    font-weight: bold;
    color: white;
  }
  
  #login-form form {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    font-family: "Poppins", sans-serif;
  }
  
  #login-form form label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: gray;
    font-family: "Poppins", sans-serif;
  }
  
  #login-form form input[type="text"],
  #login-form form input[type="password"] {
    width: 100%;
    padding: 12px;
    border: 1px solid lightgray;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;
    margin-bottom: 20px;
  }
  
  #login-form form input[type="submit"] {
    width: 100%;
    padding: 12px;
    background-color: #5c88d3;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
  }
  
  #login-form form input[type="submit"]:hover {
    background-color: deepskyblue;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  }
  